import React, { useState, useEffect } from 'react';

import LaunchIcon from '@material-ui/icons/Launch';
import DeleteIcon from '@material-ui/icons/Delete';

import { listDocuments } from '../../graphql/queries';
import { deleteDocument } from '../../graphql/mutations';

import { API, graphqlOperation, Storage } from 'aws-amplify';

import { Paper, IconButton, Tooltip, Link } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import AddDocument from '../AddDocument';

const DocumentList = () => {
	const [documents, setDocuments] = useState([]);
	const [showAddDocument, setShowAddDocument] = useState(false);

	useEffect(() => {
		fetchDocuments();
	}, []);

	const fetchDocuments = async () => {
		try {
			const documentData = await API.graphql(graphqlOperation(listDocuments));
			const documentList = documentData.data.listDocuments.items;
			setDocuments(documentList);
		} catch (error) {
			console.log('Error getting Document list:', error);
		}
	};

	const openDocument = async (idx) => {
		const documentPath = documents[idx].filePath;

		try {
			const fileAccessURL = await Storage.get(documentPath);
			window.open(fileAccessURL);
		} catch (err) {
			console.log('Failed to get file:', err);
		}
	};

	const deleteDocumentRow = async (idx) => {
		const documentToDelete = { id: documents[idx].id };

		try {
			await API.graphql(
				graphqlOperation(deleteDocument, { input: documentToDelete })
			);
		} catch (err) {
			console.log('Fail. Unable to delete object:', err.errors[0].message);
		}
		fetchDocuments();
	};

	return (
		<div className='documentList'>
			{documents.map((document, idx) => {
				return (
					<Paper variant='outlined' elevation={2} key={`document${idx}`}>
						<div className='documentCard'>
							<div>
								<div className='documentName'>
									<Link
										href='#'
										className='documentLink'
										onClick={() => openDocument(idx)}
										color='inherit'
										underline='none'
									>
										{document.name}
									</Link>
								</div>
								<div className='documentUploader'>{document.uploader}</div>
							</div>
							<div>
								<Tooltip title='Open'>
									<IconButton onClick={() => openDocument(idx)}>
										<LaunchIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title='Delete'>
									<IconButton onClick={() => deleteDocumentRow(idx)}>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					</Paper>
				);
			})}
			<div className='addDocument'>
				{showAddDocument ? (
					<div className='addDocumentOpen'>
						<AddDocument
							onUpload={() => {
								setShowAddDocument(false);
								fetchDocuments();
							}}
						/>
						<IconButton onClick={() => setShowAddDocument(false)}>
							<CancelIcon style={{ fontSize: '2rem' }}></CancelIcon>
						</IconButton>
					</div>
				) : (
					<Tooltip title='Add New File'>
						<IconButton onClick={() => setShowAddDocument(true)}>
							<AddCircleIcon fontSize='large'></AddCircleIcon>
						</IconButton>
					</Tooltip>
				)}
			</div>
		</div>
	);
};

export default DocumentList;
