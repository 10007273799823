import React from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import logo from '../../assets/PST-logo-bw.png';

const Header = () => {
	return (
		<header className='App-header'>
			<img
				src={logo}
				alt='icon'
				style={{ height: '4vh', paddingTop: '0.8vh' }}
			/>
			<h2>Emergency Services Document Store</h2>
			<AmplifySignOut />
		</header>
	);
};

export default Header;
