import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import PublishIcon from '@material-ui/icons/Publish';
import FolderIcon from '@material-ui/icons/Folder';
import { createDocument } from '../../graphql/mutations';

const AddDocument = ({ onUpload }) => {
	const [docData, setDocData] = useState({});
	const [docFileData, setDocFileData] = useState();
	const [docFileName, setDocFileName] = useState('');

	const uploadDocument = async () => {
		// validation

		console.log(`Uploading ${docData.title} from ${docData.uploader}.`);
		if (!docData.title || !docData.uploader) {
			console.log('DocData Title is empty');
			alert('Please complete the Document Name and Your Name');
			return;
		}

		if (!docFileData) {
			console.log('No file was selected for upload');
			alert('Please select a document for upload');
		}

		const { key } = await Storage.put(`${uuid()}.pdf`, docFileData, {
			contentType: 'application/pdf',
		});

		const createDocInput = {
			id: uuid(),
			name: docData.title,
			filePath: key,
			uploader: docData.uploader,
		};

		await API.graphql(
			graphqlOperation(createDocument, { input: createDocInput })
		);

		onUpload();
	};

	return (
		<div className='documentUploadForm'>
			<TextField
				required
				id='standard-helperText'
				label='Document Name'
				value={docData.title}
				onChange={(e) => setDocData({ ...docData, title: e.target.value })}
				style={{ paddingRight: '8px' }}
			/>
			<TextField
				required
				id='standard-helperText'
				label='Your Name'
				value={docData.uploader}
				onChange={(e) => setDocData({ ...docData, uploader: e.target.value })}
				style={{ paddingRight: '8px' }}
			/>
			<TextField
				id='standard-read-only-input'
				label='Your File'
				defaultValue=' '
				value={docFileName.name}
				InputProps={{ readOnly: true }}
				style={{ paddingRight: '8px', width: '300px' }}
			/>
			<input
				className='input'
				accept='application/pdf'
				id='icon-button-file'
				multiple
				type='file'
				onChange={(e) => {
					setDocFileData(e.target.files[0]);
					setDocFileName(e.target.files[0]);
				}}
				style={{ paddingRight: '8px' }}
			/>
			<label htmlFor='icon-button-file'>
				<Tooltip title='Choose File'>
					<IconButton aria-label='Choose File' component='span'>
						<FolderIcon style={{ fontSize: '2rem' }} />
					</IconButton>
				</Tooltip>
			</label>
			<Tooltip title='Upload'>
				<IconButton onClick={uploadDocument}>
					<PublishIcon style={{ fontSize: '2rem' }} />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default AddDocument;
