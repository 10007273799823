//
import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/Footer';

import DocumentList from './components/DocumentList';
import Header from './components/Header';

// Amplify deps
import { withAuthenticator } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';

// Styling
import './App.css';
import { Container } from '@material-ui/core';

Amplify.configure(awsConfig);

function App() {
	return (
		<Router>
			<div className='App'>
				<Container maxWidth='lg'>
					<Header />

					<Switch>
						<Route exact path='/'>
							<DocumentList />
						</Route>
					</Switch>

					<Footer />
				</Container>
			</div>
		</Router>
	);
}

export default withAuthenticator(App);
