/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:ee95fea9-1f9d-4ee6-b1d5-d8284e975a1d",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_YeeOWCy3w",
    "aws_user_pools_web_client_id": "1u42g8s3i26lud22g9llfah8u3",
    "oauth": {},
    "aws_user_files_s3_bucket": "esds-documents185955-master",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://f6xzxtspajg7fd6guvy5olb5wi.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
