import React from 'react';
import logo from '../../assets/PST-logo.svg';
import { Container, Typography } from '@material-ui/core';

const Footer = () => {
	return (
		<div>
			<Container>
				<img
					src={logo}
					alt='logo'
					style={{ height: '7vh', paddingTop: '3vh' }}
				/>
				<Typography
					component='div'
					style={{
						color: 'black',
						fontSize: '1.3vh',
						height: '15vh',
						padding: '5px',
						textAlign: 'center',
					}}
				>
					&copy; {new Date().getFullYear()} Peter Stanley Training Ltd
				</Typography>
			</Container>
		</div>
	);
};

export default Footer;
